import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import { SDKError, RPCError, ExtensionError } from "magic-sdk";

Vue.use(VueRouter);

const guestNoAccess = () => {
  if (window.location.href.indexOf("magic_credential") != -1) {
    return true;
  }
  localStorage.setItem("redirect", window.location.href);
  return false;
};

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/Login"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: true
            },
            {
              role: "basic",
              access: false,
              redirect: "Home"
            },
            {
              role: "trial",
              access: false,
              redirect: "Home"
            },
            {
              role: "unpaid",
              access: false,
              redirect: "Home"
            },
            {
              role: "advanced",
              access: false,
              redirect: "Home"
            },
            {
              role: "pro",
              access: false,
              redirect: "Home"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/app/Home"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/faq",
    name: "InfoPage",
    component: () => import("@/views/app/InfoPage"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/subscription-plans",
    name: "SubscriptionPlans",
    component: () => import("@/views/app/SubscriptionPlans"),
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "Login"
        }
      ]
    }
  },
  {
    path: "/account",
    component: () => import("@/views/app/profile/Index"),
    children: [
      {
        name: "UserAccount",
        path: "",
        component: () => import("@/views/app/profile/UserAccount"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/genetic-profile",
    component: () => import("@/views/app/genetic_profile/Index"),
    children: [
      {
        name: "GeneticProfile",
        path: "",
        component: () => import("@/views/app/genetic_profile/GeneticProfile"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/explorer",
    component: () => import("@/views/app/explorer/Index"),
    children: [
      {
        name: "Explorer",
        path: "view/:app",
        props: true,
        component: () => import("@/views/app/explorer/Explorer"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
      },
      {
        name: "ApdxDrugClass",
        path: "drug-classes",
        component: () => import("@/views/app/explorer/ApdxDrugClass"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
      },
      {
        name: "ApdxDiseasesGenes",
        path: "diseases-genes",
        component: () => import("@/views/app/explorer/ApdxDiseasesGenes"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
      },
      {
        name: "Explorer.gene_detail",
        path: "genes/:id",
        props: true,
        component: () => import("@/views/app/explorer/GeneDetail"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            */
          ]
        }
      },
      {
        name: "Explorer.drug_detail",
        path: "drugs/:id",
        props: true,
        component: () => import("@/views/app/explorer/DrugDetail"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
            /*
            {
              role: "basic",
              access: false,
              redirect: "SubscriptionPlans"
            }
            */
          ]
        }
      }
    ]
  },
  {
    path: "/pgx-results",
    component: () => import("@/views/app/pgx_results/Index"),
    children: [
      {
        name: "PGXResults",
        path: "",
        component: () => import("@/views/app/pgx_results/PGXResults"),
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "Login"
            }
          ]
        }
      }
    ]
  },
  {
    path: "*",
    component: () => import("@/views/auth/Index"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/app/Error")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  if (to.query.magic_credential) {
    let query = Object.assign({}, to.query);
    delete query.magic_credential;
    router.replace({ query });
  }
});

router.beforeEach((to, from, next) => {
  if (to.query.magic_credential) {
    store.commit("auth/SET_AUTH_LOADING", true);
    store
      .dispatch("auth/loginWithCredentials", to.query.magic_credential)
      .then(resp => {
        store.commit("auth/SET_STRAPI_USER_DATA", resp.data);
        router.app.$user.set({ role: resp.data.type });
        next();
      })
      .catch(err => {
        if (err instanceof SDKError) {
          console.log(err);
        }
        if (err instanceof RPCError) {
          console.log(err);
        }
        if (err instanceof ExtensionError) {
          console.log(err);
        }
      });
  } else {
    next();
  }
});

export default router;
